import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { navHeight } from 'constants/theme';

export const Container = styled.div`
  min-height: calc(100vh - ${navHeight}px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-areas: 'left right';
  ${MEDIA.MAX_LARGE`
    grid-template-columns: 2fr 1fr;
  `};
  ${MEDIA.MAX_MEDIUM`
    grid-template-columns: 100%;
    grid-template-rows: 120px 1fr;
    grid-template-areas:
      'right'
      'left'
  `};
`;

export const Right = styled.div`
  grid-area: right;
  ${MEDIA.MAX_MEDIUM`
    margin-left: -${props => props.theme.spacing.padding};
    margin-right: -${props => props.theme.spacing.padding};
  `};
`;

export const Left = styled.div`
  grid-area: left;
  ${MEDIA.MAX_MEDIUM`
    margin-left: -${props => props.theme.spacing.padding};
    margin-right: -${props => props.theme.spacing.padding};
  `};
`;
