import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import SplitContent from '../components/split-content/split-content';
import Img from 'gatsby-image';
import ContactForm from 'components/contact-form';

const Image = ({ image }) => (
  <Img
    fluid={image ? image.childImageSharp.fluid : {}}
    imgStyle={{ objectFit: 'cover' }}
    style={{ height: '100%', maxHeight: '100%' }}
  />
);

Image.propTypes = {
  image: PropTypes.object.isRequired,
};

const Contact = ({ data }) => (
  <Layout noFooterMargin>
    <Head pageTitle={data.contactJson.title} />
    <Box>
      <SplitContent
        RightComponent={() => <Image image={data.contactJson.image} />}
        LeftComponent={() => (
          <ContactForm
            title={
              data.contactJson.content.childMarkdownRemark.frontmatter.title
            }
            offices={
              data.contactJson.content.childMarkdownRemark.frontmatter.offices
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.contactJson.content.childMarkdownRemark.html,
              }}
            />
          </ContactForm>
        )}
      />
    </Box>
  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
      content {
        childMarkdownRemark {
          html
          frontmatter {
            title
            offices {
              name
            }
          }
        }
      }
      image {
        childImageSharp {
          fluid(maxHeight: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
