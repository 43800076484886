import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Container, Offices } from './contact-form.css';

const ContactForm = ({ children, title, offices }) => (
  <Wrapper>
    <Container>
      <Offices>
        {offices.map((office, i) => (
          <span key={office.name}>
            {office.name}
            {i !== offices.length - 1 && <span className="separator">/</span>}
          </span>
        ))}
      </Offices>
      <h2>{title}</h2>
      {children}
    </Container>
  </Wrapper>
);

ContactForm.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  offices: PropTypes.array.isRequired,
};

export default ContactForm;
