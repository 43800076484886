import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.secondaryLight1};
  ${MEDIA.MAX_MEDIUM`
    height: unset;
    padding-bottom: ${props => props.theme.spacing.padding};
  `};
  ${MEDIA.MAX_SMALL`
    padding-bottom: 0;
  `};
`;

export const Container = styled.div`
  width: 75%;
  border-radius: 1px;
  padding: 40px;
  ${MEDIA.MAX_LARGE`
    padding: 35px 25px;
  `};
  ${MEDIA.MAX_MEDIUM`
    width: 60%;
    padding: 35px 40px;
  `};
  ${MEDIA.MAX_SMALL`
    width: 100%;
    padding: 35px 15px;
  `};
  h2 {
    text-align: center;
    margin-bottom: calc(${props => props.theme.spacing.padding} * 2);
  }
  form {
    display: flex;
    flex-direction: column;
  }
  input,
  textarea {
    background: white;
    padding: ${props => props.theme.spacing.padding};
    margin-bottom: ${props => props.theme.spacing.padding};
  }
  button {
    color: white;
    border-radius: 1px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: ${props => props.theme.colors.primary};
    padding: ${props => props.theme.spacing.padding}
      calc(${props => props.theme.spacing.padding} * 2);
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Offices = styled.div`
  ${MEDIA.MAX_MEDIUM`
    flex-wrap: wrap;
  `};
  display: flex;
  justify-content: center;
  margin-bottom: calc(${props => props.theme.spacing.padding} * 2);
  span {
    font-size: ${props => props.theme.font.size.smaller};
    text-transform: uppercase;
    white-space: nowrap;
  }
  .separator {
    color: white;
    font-weight: bold;
    padding: 0 4px;
  }
`;
