import React from 'react';
import PropTypes from 'prop-types';
import { Container, Right, Left } from './split-content.css';

const SplitContent = ({ RightComponent, LeftComponent }) => (
  <Container>
    <Left>
      <LeftComponent />
    </Left>
    <Right>
      <RightComponent />
    </Right>
  </Container>
);

SplitContent.propTypes = {
  RightComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  LeftComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default SplitContent;
